import React, { useState, useEffect } from 'react';
import './FsmTable.css';
import TableComponent from '../../../Ui/TableComponent/TableComponent';
import axios from 'axios';

const FsmTable = (props) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableData , setTableData] = useState([])
  const [showLoader, setShowLoader] = useState(true);
  const {settings} = props
  useEffect(() => {
    const fetchTableDataUrl = `https://icma-dev.siemens-energy.cloud/as-9059_pi/pi.fsmfetch/dataWith90Days`;
        axios
          .get(fetchTableDataUrl, {
            headers: {
              Authorization: `Bearer ${settings.token}`
            }
          })
          .then((tableResponse) => {
            setTableData(tableResponse?.data?.body)
            setShowLoader(false)
          }).catch((error) => {
              console.log(error);
            }).finally((error) => {
              setShowLoader(false);
            });
    }, []);

  const tableOptions = [
    {
      Header: 'PPID',
      accessor: `id`,
      i18n: false
    },
    {
      Header: 'Plant Name',
      accessor: 'plantName',
      i18n: false
    },
    {
      Header: 'File Name',
      accessor: 'fileName',
      i18n: false
    },
    {
      Header: 'File Path',
      accessor: 'filePath',
      i18n: false
    },
    {
      Header: 'Upload Type',
      accessor: 'uploadType',
      i18n: false
    },
    {
      Header: 'Upload Status',
      accessor: 'fileStatus',
      i18n: false
    },
    {
      Header: 'Upload Date',
      accessor: 'uploadDate',
      i18n: false
    },
    {
      Header: 'Error Message',
      accessor: 'errorMessage',
      i18n: false
    }
  ];

  const handleRowClick = (index) => {
    setSelectedRow(index);
    console.log(selectedRow)
    // Add your logic here for handling row clicks
  };

  const onRowSelectHandler = (selectedRow) => {
    console.log("selectedRows: >>", selectedRow)
  };

  return (
    <div>
      <TableComponent
        data={tableData}
        sortable={true}
        enableColumnFilter={false}
        enableSelection={false}
        enableSelectAll={false}
        globalFilter={false}
        onRowSelect={onRowSelectHandler}
        columns={tableOptions}
        isLoading={showLoader}
        onselectedRows={handleRowClick}
      />
    </div>
  );
};

export default FsmTable;
