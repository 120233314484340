import React from 'react';
import classes from './Footer.module.css';
import SiemensEnergyLogo from '../../assets/images/siemens-energy_logo.png';
const Footer = (props) => {
  return (
    <div className={`${classes.Footer}`} component="footer" variant="outlined">
      <div className={`${classes.displayFlex}`}>
        <div className={`${classes.logo}`}>
          <span className={`${classes.copyRight}`}>&copy;</span>
          <img
            src={SiemensEnergyLogo}
            className={classes.SiemensEnergyLogo}
            alt="Siemens-Energy"
          ></img>
          <span className={classes.licenceText}>
            is a trademark licenced by Siemens Energy.
          </span>
        </div>
        <div className={`${classes.icmaFooter}`}>
          <div className={`${classes.icmsText}`}>I&C Monitors and Advisors</div>
          {props.version_info.latest_version && (
            <span
              onClick={props.openVersionModal}
              className={`${classes.versionBadge}`}
            >
              {props.version_info.latest_version}
            </span>
          )}
        </div>
        <div
          onClick={props.openTermsAndConditions}
          className={`${classes.thirdparty}`}
        >
          <span className={`${classes.thirdpartyLink}`}>Third Party Software</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
